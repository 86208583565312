import {
    EN_PRICES,
    EN_RESOURCES,
    EN_SERVICE,
    ENGLISH_MAIN_ROUTE,
    EN_US,
    ES_SERVICE,
    ES_PRICES,
    ES_RESOURCES,
    ES_US,
    LOGIN_OWNERS
} from "../../routes/constantsRoutes";

export interface PathRouteInterface {
    id: string,
    name_es: string,
    name_en: string,
    path_es: string,
    path_en: string,
    translateName: string,
    target: string,
}

export const navBarPagesValues: Array<PathRouteInterface> = [
    {
        'id': 'service',
        'name_es': 'Servicios',
        'name_en': 'Services',
        'path_es': ES_SERVICE,
        'path_en': ENGLISH_MAIN_ROUTE + EN_SERVICE,
        'translateName': 'service',
        'target': '_self'
    },
    {
        'id': 'prices',
        'name_es': 'Precios',
        'name_en': 'Prices',
        'path_es': ES_PRICES,
        'path_en': ENGLISH_MAIN_ROUTE + EN_PRICES,
        'translateName': 'prices',
        'target': '_self'
    },
    {
        'id': 'resources',
        'name_es': 'Recursos',
        'name_en': 'Resources',
        'path_es': ES_RESOURCES,
        'path_en': ENGLISH_MAIN_ROUTE + EN_RESOURCES,
        'translateName': 'resources',
        'target': '_self'
    },
    {
        'id': 'our',
        'name_es': 'Nosotros',
        'name_en': 'our',
        'path_es': ES_US,
        'path_en': ENGLISH_MAIN_ROUTE + EN_US,
        'translateName': 'our',
        'target': '_self'
    },
    {
        'id': 'property',
        'name_es': 'Propietarios',
        'name_en': 'Properties',
        'path_es': LOGIN_OWNERS,
        'path_en': LOGIN_OWNERS,
        'translateName': 'property',
        'target': '_blank'
    }
];

export const navBarEstimationValue: PathRouteInterface =
    {
        'id': 'estimate',
        'name_es': 'Obtener una estimación',
        'name_en': 'Get an estimate',
        'path_es': ES_PRICES,
        'path_en': ENGLISH_MAIN_ROUTE + EN_PRICES,
        'translateName': 'estimate',
        'target': '_self'
    };

