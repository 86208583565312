import React, {useState} from "react";
import {AppBar, Box, Container, Grid, IconButton, Link, Toolbar} from "@mui/material";
import {navBarPagesValues, navBarEstimationValue, PathRouteInterface} from "./NavBarPages";
import MenuIcon from '@mui/icons-material/Menu';
import SideMenuDrawer from "../drawer/drawer";
import {WINAHOST_HORIZONTAL_LOGO} from "../../Shared/Constants/imageConstants";
import ScreenSizeResource from "../../Shared/utils/screenSizeResource";
import {ES_PRICES} from "../../routes/constantsRoutes";
import {useLanguageContext} from "../../context/languageProvider";


function NavBar() {

    const lang = useLanguageContext();

    const {width} = ScreenSizeResource();

    const [openDrawer, setOpenDrawer] = useState(false);

    const handleOpenDrawerMenu = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawerMenu = () => {
        setOpenDrawer(false);
    };

    const mainRoute: string = (lang.language === 'es') ? '/' : `/${lang.language}/`;


    let setMargin = 15;
    if (width <= 1200) {
        setMargin = 15;
    }
    if (width <= 1100) {
        setMargin = 5;
    }

    const styles = {
        logoImageSize: {
            width: (width >= 500) ? '180px' : '160px'
        },
        estimateContent: {
            width: 420,
            margin: `40px ${setMargin}px 20px ${setMargin}px`,
            padding: '5px 10px',
            color: 'white',
            backgroundColor: '#008080',
            borderRadius: 5,
            boxShadow: '0 2px 5px 0 rgba(0,0,0,0.46)'
        },
        estimateText: {
            fontSize: '12px'
        }
    }

    return (
        <AppBar position="fixed" style={{backgroundColor: 'white', boxShadow: 'none'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{display: {xs: 'none', md: 'flex'}, mr: 1, mx: 2}}>
                        <Link rel={'canonical'} key={'main_page'} href={mainRoute}>
                            <img src={WINAHOST_HORIZONTAL_LOGO}
                                 alt={"logo winahost"}
                                 style={{width: '280px'}}
                            />
                        </Link>
                    </Box>


                    <Box sx={{flexGrow: 0, display: {xs: 'flex', md: 'none'}}} style={{width: '100%'}}>
                        <Grid container justifyContent={'flex-start'}>
                            <Grid item xs={5} textAlign={'left'}>
                                <Link rel={'canonical'} key={'main_page'} href={mainRoute}>
                                    <img src={WINAHOST_HORIZONTAL_LOGO}
                                         alt={"logo winahost"}
                                         style={{width: styles.logoImageSize.width, marginRight: 0}}
                                    />
                                </Link>
                            </Grid>

                            {(width >= 380) && (
                                <Grid item xs={7} mt={2} textAlign={'end'}>
                                <Link
                                    rel={'canonical'}
                                    key={'1'}
                                    underline="none"
                                    href={ES_PRICES + '#calculatorDegree'}
                                    style={{...styles.estimateContent, ...styles.estimateText}}
                                    target={'_self'}
                                >
                                    {'Obtén tu estimación'}
                                </Link>
                            </Grid>
                            )}
                        </Grid>
                        <IconButton
                            aria-label="button"
                            role="button"
                            size="large"
                            onClick={handleOpenDrawerMenu}
                            color={"default"}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'flex-end'}}>
                        {navBarPagesValues.map((page: PathRouteInterface) => {
                                return (
                                    <Link
                                        rel={'canonical'}
                                        key={page.id}
                                        underline="none"
                                        href={page[`path_${lang.language}`]}
                                        style={{
                                            margin: `20px ${setMargin}px`,
                                            padding: '5px 10px',
                                            color: 'black',
                                            backgroundColor: 'white',
                                            borderRadius: 35,
                                            boxShadow: '0 2px 5px 0 rgba(0,0,0,0.46)'
                                        }}
                                        target={page.target}
                                    >
                                        {page[`name_${lang.language}`]}
                                    </Link>
                                )
                            }
                        )}
                        <Link
                            rel={'canonical'}
                            key={'1'}
                            underline="none"
                            href={navBarEstimationValue[`path_${lang.language}`] + '#calculatorDegree'}
                            style={{
                                width: 220,
                                margin: `20px ${setMargin}px`,
                                padding: '5px 10px',
                                color: 'white',
                                backgroundColor: '#008080',
                                borderRadius: 5,
                                boxShadow: '0 2px 5px 0 rgba(0,0,0,0.46)',
                                textAlign: 'center'
                            }}
                            target={navBarEstimationValue.target}
                        >
                            {navBarEstimationValue[`name_${lang.language}`]}
                        </Link>
                    </Box>

                    <SideMenuDrawer
                        openDrawer={openDrawer}
                        closeDrawer={handleCloseDrawerMenu}
                        pages={navBarPagesValues}
                        lang={lang.language}
                    />
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavBar;
